<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">智慧托育场景</span>
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width: 5px; height: 6rem" />
          </div>
        </div>
      </div>
      <div>
        <!--        <div style="width: 100%; height: 460px;position: relative">-->
        <!--          <img src="../../public/image/左.png" alt="" style="position: absolute;left: 0">-->
        <!--          <img src="../../public/image/右.png" alt="" style="position: absolute;right: 0">-->
        <!--          <img src="https://www.libaoty.com/file/lb//20230821//1.png" style="width: 100%;height: 100%;" alt="">-->
        <!--        </div>-->
        <el-carousel

          trigger="click"
          height="450px"
          interval="5000"
          style="margin-top: 10px"
          :autoplay="true"
        >
          <el-carousel-item v-for="(item, index) in imageBigList" :key="index">
            <!--            <el-image-->
            <!--                style="width: 100%; height: 100%"-->
            <!--                :src="'https://www.libaoty.com/file/' + item.filedir"-->
            <!--                :preview-src-list="imageBigList">-->
            <!--            </el-image>-->
            <img
              :src="item"
              @click="bigImg(index)"
              style="width: 100%; height: 100%"
            />

            <!-- <img  :src="'https://www.libaoty.com/file/' + item.filedir" @click="bigImg(item.filedir,index)" style="width: 100%;height: 100%;"> -->
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="100%"
      :before-close="handleClose"
    >
      <div style="position: relative">
        <el-carousel
          @change="messageShow = false"
          :initial-index="index"
          trigger="click"
          height="800px"
          style="margin-top: 10px"
          :autoplay="false"
        >
          <el-carousel-item v-for="(item, index) in imageBigList" :key="index">
            <!--            <el-image-->
            <!--                style="width: 100%; height: 100%"-->
            <!--                :src="'https://www.libaoty.com/file/' + item.filedir"-->
            <!--                :preview-src-list="imageBigList">-->
            <!--            </el-image>-->
            <div
              v-if="messageShow"
              style="
                padding: 20px;
                width: 250px;
                height: 200px;
                background-color: rgba(84, 122, 245, 0.2);
                position: absolute;
              "
              :style="{ top: top, left: left }"
            >
              <div
                style="
                  line-height: 25px;
                  font-size: 20px;
                  font-weight: bold;
                  color: white;
                  margin-bottom: 20px;
                  margin-top: 20px;
                "
              >
                {{ messageTitle }}
              </div>
              <div style="color: white">{{ messageBody }}</div>
            </div>
            <img
              :src="item"
              @click="bigImgClick(index)"
              style="width: 100%; height: 100%"
            />
<!-- <s><img src="../../public/image/00.png" alt=""></s> -->
            <!-- <img  :src="'https://www.libaoty.com/file/' + item.filedir" @click="bigImgClick(index)" style="width: 100%;height: 100%;"> -->
          </el-carousel-item>
        </el-carousel>
        <!--<img :src="imgSrc" style="width: 100%;height: 100vh;">-->
        <!--<div v-if="Show" style="padding: 20px; width: 250px;height: 200px;background-color:rgba(84,122,245,0.2); position: absolute;bottom: 30%;right: 12%">-->
        <!--  <div style="line-height:25px;font-size: 20px;font-weight: bold;color: white;margin-bottom: 20px;margin-top: 20px">智能人脸识别测湿一体<br>立柱机(成人+儿童双算法)</div>-->
        <!--  <div style="color: white">童脸自动识别，安全考勤测温连接系统。</div>-->
        <!--</div>-->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { imageList } from "../api/index";
// import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  components: {
    // BottomRightChart
  },
  data() {
    return {
      top: "",
      left: "",
      messageShow: "",
      messageTitle: "",
      messageBody: "",
      dialogVisible: false,
      index: 0,
      oneShow: false,
      twoShow: false,
      thereShow: false,
      fourShow: false,
      fiveShow: false,
      sixShow: false,
      oneDialogVisible: false,
      twoDialogVisible: false,
      thereDialogVisible: false,
      fourDialogVisible: false,
      fiveDialogVisible: false,
      sixDialogVisible: false,
      image: [],
      imageBigList: [
        require("../../public/image/00.png") ,
        require("../../public/image/01.png") ,
        require("../../public/image/02.png") ,
        require("../../public/image/13.png") ,
        require("../../public/image/14.png") ,
      
      
       
      ],
      imgSrc: "",
    };
  },
  mounted() {
    this.getImg();
  },
  methods: {
    getImg() {
      imageList().then((res) => {
        this.image = res.data.records;
      });
    },
    bigImgClick() {
      this.dialogVisible = false
    },
    bigImg() {
      this.dialogVisible = true;
      this.index = 0;
      //  this.imgSrc='https://www.libaoty.com/file/' + value
      // if (item===7){
      //   this.sixDialogVisible=true
      //
      // }else if (item===5){
      //   this.fiveDialogVisible=true
      //
      // }else if (item===4){
      //   this.fourDialogVisible=true
      // }else if (item===6){
      //   this.thereDialogVisible=true
      //
      // }else if (item===3){
      //   this.twoDialogVisible=true
      // }else if (item===2){
      //   this.oneDialogVisible=true
      // }
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="scss" class>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

$box-height: 520px;
$box-width: 100%;
#bottomRight {
  padding: 14px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
