<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue';
// import {getStudentList,getGarden} from '@/api/index'
export default {
  data() {
    return {
      cdata: {
        category: [
          '太科城（新安）立宝托育中心',
          '隽宝宝托育中心',
          '波士顿托育',
          
        ],
        lineData: [
          150,
          150,
          20,
         

        ],
        barData: [
        
          57,
          38,
          0,
        ],
        rateData: []
      }
    };
  },
  components: {
    Chart
  },
  watch: {
    city(val) {
      console.log(val, 'left1');
      this.beck = val;
      switch(val)
      {
        case "无锡市":
          this.getGarden()
          break;
        case "南通市":
          this.getGarden()
          break;
        case "江苏省":
          this.getcity()
          break;
        default:
          this.getcity()
          // this.setData=this.cdata
      }

    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      for (let i = 0; i <= this.cdata.barData.length - 1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
   
  }
}
// export default {
  // data() {
  //   return {
  //     garden: "",
  //     cdata: {
  //       category: [],
  //       lineData: [
  //       ],
  //       barData: [],
  //       rateData: []
  //     }
  //   };
  // },
  // components: {
  //   Chart
  // },
  // created() {
  //   this.setData()

  //   // console.log(this.cdata.barData)
  // },
  // methods: {
    // async getGod() {
    //   // this.garden = localStorage.getItem('garden')
    //   let a = ""
    //   let b = []
    //   let c = ''
    //   let d = []
    //   let e=[]
    //   // console.log(this.garden,"this.garden");
    //   for (const item of this.garden.split(";")) {
    //     c = await this.gardenList(item.split(",")[2])
    //     e.push(item.split(",")[2])
    //     d.push(c.data.records[0].countNumber)
    //     a = await this.getList(item)
    //     b.push(a.data.records.length)
    //   }
    //   // console.log(e,b,d,"22s");
    //   this.cdata.category = e.reverse()
    //   this.cdata.barData = b.reverse()
    //   this.cdata.lineData=d.reverse()
    //   this.setData()
    // },
    // getList(item) {
    //  return getStudentList(item)
    // },
    // gardenList(item){
    //   return getGarden(item)
    // },

    // 根据自己的业务情况修改
    // setData() {
    //   for (let i = 0; i <= this.cdata.barData.length - 1; i++) {
        
    //     this.cdata.barData=[91,0,0,0,63]
    //     this.cdata.lineData=[150,20,120,60,150]
    //     let rate = this.cdata.barData[i] / this.cdata.lineData[i];
    //     this.cdata.rateData.push(rate.toFixed(2));
    //   }
    // },
  // }
// };
</script>

<style lang="scss" scoped>
</style>