<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      cityData: [],
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
  watch: {
    cdata: {
      handler(newData) {
        this.cityData = newData;
        // console.log(newData,"123");
        // newData.barData=[70,0,32,19,63]
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            formatter: function (params) {
              // params 是一个数组，包含了当前数据点的信息
              // 假设 newData.rateData 是小数形式的数据
              // console.log(params, "params");
              var value = (params[0].value * 100).toFixed(0); // 转换为百分比并保留两位小数
              var seriesName = params[0].axisValueLabel; // 系列名称
              var xAxisValue = params[0].seriesName; // x轴的值
              var xAxisValue1 = params[1].seriesName;
              var xAxisValue2 = params[2].seriesName;
              var value1 = params[1].value;
              var value2 = params[2].value;
              var htmlContent =
                '<div style="display: inline-block; margin-right: 8px;">' + // 右边距
                '<span class="custom-dot" style="background-color: #F02FC2; width: 10px; height: 10px; border-radius: 50%; display: inline-block;"></span>' + // 带有颜色的圆点
                "</div>";
              var htmlContent1 =
                '<div style="display: inline-block; margin-right: 8px;">' + // 右边距
                '<span class="custom-dot" style="background-color: #3EACE5; width: 10px; height: 10px; border-radius: 50%; display: inline-block;"></span>' + // 带有颜色的圆点
                "</div>";
              var htmlContent2 =
                '<div style="display: inline-block; margin-right: 8px;">' + // 右边距
                '<span class="custom-dot" style="background-color: rgba(156,107,211,0.8); width: 10px; height: 10px; border-radius: 50%; display: inline-block;"></span>' + // 带有颜色的圆点
                "</div>";

              // 你可以根据需要自定义返回的字符串格式
              return (
                seriesName +
                "<br/>" +
                htmlContent +
                xAxisValue +
                ": " +
                value +
                "%" +
                "<br/>" +
                htmlContent1 +
                xAxisValue1 +
                ": " +
                value1 +
                "<br/>" +
                htmlContent2 +
                xAxisValue2 +
                ": " +
                value2
              );
            },
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              },
            },
          },
          legend: {
            data: ["已入托", "托位数", "入托率"],
            textStyle: {
              color: "#B4B4B4",
            },
            top: "0%",
          },
          grid: {
            x: "5%",
            width: "90%",
            y: "10%",
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              interval: 0,
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },

              axisLabel: {
                formatter: "{value} ",
              },
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },
              axisLabel: {
                formatter: function (value) {
                  // 假设 value 是 0 到 1 之间的小数，表示百分比（例如 0.5 表示 50%）
                  // 如果 value 不是这种格式，你可能需要先将其转换为这种格式
                  var percentValue = (value * 100).toFixed(0); // 乘以 100，并保留两位小数
                  return percentValue + "%"; // 添加百分号
                },
              },
            },
          ],
          series: [
            {
              name: "入托率",
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#F02FC2",
                  // color: "#aaaaaa",
                },
              },

              data: newData.rateData,
            },
            {
              name: "已入托",
              type: "bar",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#956FD4" },
                    { offset: 1, color: "#3EACE5" },
                  ]),
                },
              },
              label: {
                show: true, // 开启标签显示
                position: "top", // 标签位置在顶部
                color: "#3EACE5",
                formatter: function (params) {
                  // 返回要显示的标签内容，这里直接返回数据值
                  return params.value;
                },
              },
              data: newData.barData,
            },
            {
              name: "托位数",
              type: "bar",
              barGap: "-100%",
              barWidth: 10,
              itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "rgba(156,107,211,0.8)" },
                    { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                    { offset: 1, color: "rgba(156,107,211,0.2)" },
                  ]),
                },
              },
              z: -12,
              label: {
                show: true, // 开启标签显示
                position: "top", // 标签位置在顶部
                color: "rgba(156,107,211,0.8",
                formatter: function (params) {
                  // 返回要显示的标签内容，这里直接返回数据值
                  return params.value;
                },
              },
              data: newData.lineData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {},
};
</script>
