<template>
  <div>
    <Chart  :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
// 使用vux的 mapState需要引入
import { mapState } from 'vuex';

export default {
  data() {
    return {
      setData:[],
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '新安街道',
          value: [450,1,2],
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          }
        },

      ],

    };
  },
  components: {
    Chart,
  },

  computed: {
    ...mapState({
      // 等价于上面的写法
      city: state => state.city
    })

  },

  mounted() {
    // console.log(this.$store.state.city,"state");
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
</style>