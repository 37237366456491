<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="colorBlue">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"


            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">各园区托位数</span>
          <Scroll :config='ranking' slot="reference" scroll-wrapper=“peopleScrollWrapperOne” scroll-list=“peopleScrollListOne” @click='clickPeopleType' />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose">
      <div style="padding: 5px 40px 10px 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{dialogMessage}}
      </div>
      <div style="width: 1000px;display: flex;align-items: center;justify-content: space-between;padding: 0px 40px 10px 20px;">
        <div style="width: 500px"><img :src="imgSrc" alt="" style="width: 400px;height: 300px;"></div>
        <div  style="width: 500px">
          <div id="main"  style="display: flex;align-items: center;justify-content: center;">
            <Chart :cdata="cdata" />
          </div>
        </div>

      </div>
      <span></span>
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
<!--  </span>-->
    </el-dialog>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import {getGarden} from '../api/index'
import Scroll from '@/components/dv-scroll/Scroll.vue'

export default {
  data() {
    return {
      studentNumber:0,
      cdata: {
        name:"",
        xData: ["托位数", "已入托"],
        seriesData: [
          { value: 150, name: "托位数" },
          { value: 120, name: "已入托" },
        ],
      },
      dialogVisible: false,
      dialogTitle:"",
      gardenNumber:"",
      dialogMessage:"",
      imgSrc:"",
      message:"",
      comfig:{
        data:[
          {name:'第一',value:'111'}, {name:'第三',value:'22'}, {name:'第二',value:'35'}
        ],
        unit:'人'
      },
      title:"立宝托育",
      titleItem: [
      ],
      ranking: {
        data: [
          {
            name: '太科城（新安）立宝托育中心',
            value: 150
          },
          {
            name: '隽宝宝托育中心',
            value: 150
          },
          {
            name: '波士顿托育',
            value: 20
          },
        ],
        carousel: 'single',
        unit: '人',
        index:true
      },
      water: {
        data: [24, 45],
        shape: 'roundRect',
        formatter: '{value}%',
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 60,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 40,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ],
      peopleScrollShow: false
    }
  },
  components: {
    Scroll,
    Chart
    // centerLeft1
    // CenterChart
  },
  computed: {

  },
  created () {

    this.titleItem= [
      {
        title: '总托位数',
        number: {
          number: [320],//[Number(localStorage.getItem("studentConut"))],
          toFixed: 0,
          textAlign: 'center',
          content: '{nt}',
          style: {
            fontSize: 26
          }
        }
      },
      {
        title: '托育机构数',
        number: {
          number: [3],
          toFixed: 0,
          textAlign: 'center',
          content: '{nt}',
          style: {
            fontSize: 26
          }
        }
      },
      {
        title: '开园数',
        number: {
          number: [2],
          toFixed: 0,
          textAlign: 'center',
          content: '{nt}',
          style: {
            fontSize: 30
          }
        }
      },
      {
        title: '今日入托数',
        number: {
          // number: [Number(localStorage.getItem("studentNumber"))],//实时
          number: [95],
          toFixed: 0,
          textAlign: 'center',
          content: '{nt}',
          style: {
            fontSize: 30
          }
        }
      },
    ]
  },
  methods: {

   async clickPeopleType(e){
      this.dialogTitle = e.name
      getGarden(e.name).then(res=>{
        this.dialogMessage=res.data.records[0].gardenDetail
        this.imgSrc =res.data.records[0].picUrl
        this.cdata.name = e.name
        var studentList = res.data.records[0].countNumber
        // console.log(e.name);
        let studentConut=[57,38,0]
        if (e.name==="太科城（新安）立宝托育中心") {
          this.cdata.seriesData= [
            { value: studentList-studentConut[0], name: "未入托" },
            { value: studentConut[0], name: "已入托" },
          ]
        
          // console.log( this.seriesData
        }
        if (e.name==="隽宝宝托育中心") {
          this.cdata.seriesData= [  
            { value: studentList-studentConut[1], name: "未入托" },
            { value: studentConut[1], name: "已入托" },
          ]
        
          // console.log( this.seriesData
        }
        
        if (e.name==="波士顿托育") {
          this.cdata.seriesData= [
            { value: studentList-studentConut[2], name: "未入托" },
            { value: studentConut[2], name: "已入托" },
          ]
        
          // console.log( this.seriesData
        }
        
        this.dialogVisible = true
        
      })
      // console.log(this.cdata,"121212");
    },
    handleClose(done) {
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding: 0;
}
::v-deep .el-dialog__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-dialog__header{
  //text-align: center;
}

#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 47%;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 100%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {

        width: 47%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
