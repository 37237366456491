<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="230px"
      width="260px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          color: [
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          toolbox: {
            show: true,
          },

          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: -0,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              name: "托位数",
              type: "pie",
              radius: [20, 50],
              // roseType: "area",
              center: ["50%", "40%"],
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    // position: "outside",
                    color: "#ddd",
                  },
                  labelLine: {
                    length: 5,
                    // length2: 10,
                    show: true,
                    // color: "#00ffff",
                  },
                },
              },
              with: 200,
              data: newData.seriesData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
