<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10"/>
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor"/>
            <div class="title">
              <span class="title-text">新安街道智慧托育服务大数据平台</span>
              <dv-decoration-6
                  class="dv-dec-6"
                  :reverse="true"
                  :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
                class="dv-dec-8"
                :reverse="true"
                :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s"/>
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>

            </div>
            <div class="react-left ml-3">
              <!-- <span class="text-img2"><img style="width: 100%;height: inherit" src="../../public/image/大气温度.png" -->
                                           <!-- alt=""></span> -->
              <!-- <span class="text">{{ tem }}℃</span> -->
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-black mr-3">
              
              
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
              >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-8 :reverse="true" style="width: 480px;">

                <el-popover
                    placement="right-start"
                    title="新安街道"
                    width="280"
                    trigger="click"
                    content="这是一段内容,这是一段内容,这是一段内容<br>,这是一段内容。">
                  <img src="../../public/image/新安.jpg" alt="" style="width: 240px;height: 100px;">
                  <div>
                    <p>1.新安街道共有人口6.24万。</p>
                    <p>2.目前有已成功备案机构3个：<br>&nbsp;&nbsp;&nbsp;立宝托育（托位数150）；<br>&nbsp;&nbsp;&nbsp;隽宝宝托育中心（托位数150）；<br>&nbsp;&nbsp;&nbsp;波士顿托育（托位数20）；
                    </p>
                    <p>3.预计在新安花苑第三社区、菱湖社区、净湖社区建设三个社区托育点。
                    </p>
                    <p>
                      4.截止2022年12月31日，0-3岁婴幼儿共计1859人。
                    </p>
                  </div>
                  <centerLeft1 slot="reference" @click="visible = !visible"/>
                </el-popover>
              </dv-border-box-8>
              <!--              <dv-border-box-12>-->
              <!--               -->
              <!--              </dv-border-box-12>-->
            </div>

            <!-- 中间 -->
            <div>
              <dv-border-box12 style="width: 900px">
                <center/>
              </dv-border-box12>
            </div>
            <div>
              <dv-border-box-8 style="width: 480px;">
                <centerLeft2/>
              </dv-border-box-8>
            </div>
            <!-- 中间 -->
            <!--            <div>-->
            <!--              <centerRight2 />-->
            <!--            </div>-->
            <!--            <div>-->
            <!--              <dv-border-box-13>-->
            <!--                <centerRight1 />-->
            <!--              </dv-border-box-13>-->
            <!--            </div>-->
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <dv-border-box-13>
              <bottomLeft/>
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight/>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import {formatTime} from '@/utils';
import {visitorList} from "../api/index"
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
// import centerRight1 from "./centerRight1";
// import centerRight2 from "./centerRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
// import invoke from "../ajax";

export default {
  mixins: [drawMixin],
  data() {
    return {
      wea_img: "",
      tian: "暴雪",
      tem: "",
      wea: "",
      province: "",
      city: "",
      name: "",
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
      prop: [
        {
          value: "all",
          label: "全国",
          leaf: false,
          type: "root",
          disabled: false,
        },
      ],
      props: {
        // emitPath:false,//返回值只有value
        // multiple: true, //多选模式
        // multiple: true,
        checkStrictly: true,
        lazy: true, //	是否动态加载子节点，需与 lazyLoad 方法结合使用
        lazyLoad: this.handleGetClassOrStudent, //懒加载调用的方法
      },
    };
  },
  components: {
    centerLeft1,
    centerLeft2,
    center,
    bottomLeft,
    bottomRight,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.getWeaAndTem()
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    unique(arr) {
      return arr.filter(function (item, index, arr) {
        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index;
      });
      // !
      // *
    },
    handleGetClassOrStudent(node, resolve) {
      //node相当于点击的选项，其中有上一级的信息
      //第一次调用时 node中什么也没有
      //resolve() 返回的内容是子选项
      console.log(node.level, node.label);
      const {data} = node; //等同于const data = node.data
      const {parent} = node;
      console.log(data, parent, resolve);
      if (node.level == 1) {
        let query = ""
        visitorList(query).then(res => {
          const newdata = res.data.records.map((item) => {
            return item.province;
          });
          const nodes = Array.from(this.unique(newdata), (item) => ({
            //于stream流差不多，将后台返回的数据进行处理
            value: item, //选中项绑定值
            label: item, //标签上显示文字
            leaf: false, //是否有子选项
            type: "provinceType", //这是自己定义的 方便区分
          }));
          resolve(nodes);
        })

      } else if (node.level == 2) {
        this.province = node.label;
        let query = `province=${this.province}`
        visitorList(query).then(res => {
          const newdata = res.data.records.map((item) => {
            return item.city;
          });
          const nodes = Array.from(this.unique(newdata), (item) => ({
            //于stream流差不多，将后台返回的数据进行处理
            value: item, //选中项绑定值
            label: item, //标签上显示文字
            leaf: false, //是否有子选项
            type: "cityType", //这是自己定义的 方便区分
          }));
          // console.log(nodes);
          resolve(nodes);
        });
      } else if (node.level == 3) {
        // province=${this.province}&city=${this.city}
        this.city = node.label;
        let query = ` province=${this.province}&city=${this.city}`
        visitorList(query).then(res => {
          const newdata = res.data.records.map((item) => {
            return item.name;
          });
          const nodes = Array.from(this.unique(newdata), (item) => ({
            //于stream流差不多，将后台返回的数据进行处理
            value: item, //选中项绑定值
            label: item, //标签上显示文字
            leaf: true, //是否有子选项
            type: "garden", //这是自己定义的 方便区分
          }));
          // console.log(nodes);
          resolve(nodes);
        });
      }
    },
    getWeaAndTem() {
      this.$axios({
            methods: 'get',
            url: 'https://tianqiapi.com/free/day',
            headers: {},
            params: {
              appid: '42341372', // 账号ID
              appsecret: 'n3JDq3Nz', // 账号密钥
              cityid: null, // 城市编号,不要带CN, 以下参数3选1，当前以西湖区举例
              city: null, // 城市名称,不要带市和区
              ip: null, // IP地址
              callback: 0, // jsonp方式
            },
          })
          .then((res) => {
            console.log(res);
            // const date = res.data.date;
            // const week = res.data.week;
            // this.dateAndWeek = date + week;
            this.wea = res.data.wea;
            this.tem = res.data.tem;
            this.wea_img = res.data.wea_img
            // const  wea = '大气温度'
            // this.tian=`../../public/image/${wea}.png`

          })
          .catch((error) => {
            console.log(error);
          });
    },

    //选择器选择后触发
    handleClassOrStudentChange(node) {
      if (node.length === 1) {
        this.$store.commit('upCity', "江苏省")
      } else if (node.length <= 2) {
        this.$store.commit('upCity', node[node.length - 1])
      } else {
        this.$store.commit('upCity', node[2])
      }

      this.$store.commit('upProvince', node[1])

    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },

};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
</style>
<style lang="scss">
.text-img2 {
  display: inline-block;
  position: absolute;
  left: 70px;
  top: 10px;
  width: 30px;
  height: 30px;
  transform: skewX(-45deg);
}

.text-img {
  display: inline-block;
  position: absolute;
  left: 70px;
  top: 10px;
  width: 30px;
  height: 30px;
  transform: skewX(45deg);
}

.el-cascader {
  transform: skewX(-45deg) !important;
  //background-color: red;
}

.el-cascader .el-input .el-input__inner {
  background: #0f1325 !important;
  color: white !important;
  font-weight: bold;
}

.pc-sel-area-cascader {
  // 选择面板样式

  .el-cascader-panel {
    //background: #0f1325;
    //color: white !important;
  }

  .el-cascader-menu__wrap {
    // 设置选择器省市区分块面板高度
  }

  .el-cascader-menu {
    // 省市区分块右边框
    border: none;
  }

  .el-scrollbar__thumb {
    // 上下滚动条
    display: none;
  }

  .el-cascader-node {
    color: white !important;
    height: 40px;
  }

  .el-cascader-node:hover {
    // 设置鼠标滑过时文字颜色
    color: white;
    background: #0f1325;
  }

  .el-cascader-node__label {
    // 设置文字样式
    padding: 0 7px;
    font-size: 14px;
    font-weight: 400;
  }

  // 文字选中样式及span背景颜色
  .el-cascader-node.in-active-path,
  .el-cascader-node.is-active,
  .el-cascader-node.is-selectable.in-checked-path {
    color: #487eff !important;
    background: #0f1325;
  }

  .el-icon-check {
    // 去掉选中小对勾
    display: none;
  }

  .el-icon-arrow-right {
    // 选项去掉右侧小图标
    display: none;
  }

  // 选择器面板边框及圆角设置
  background: black !important;
  color: white;
  border-radius: 12px !important;
  border: 1px solid #f6f7f8 !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07) !important;
}

.pc-sel-area-cascader[x-placement^="bottom"] {
  // 选择器面板与输入框的距离
  margin-top: 1px !important;
}

.pc-sel-area-cascader[x-placement^="bottom"] .popper__arrow {
  // 输入框下面小三角形
  display: none;
}
</style>
