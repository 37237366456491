<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
// import { visitorList } from "@/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      newXData: "",
      newSeriesData: "",
      cdata: {
        xData: ["太科城（新安）立宝托育中心", "隽宝宝托育中心","波士顿托育" ],
        seriesData: [
          { value: 20, name: "波士顿托育" },
          { value: 150, name: "隽宝宝托育中心" },
          { value: 150, name: "太科城（新安）立宝托育中心" },
        ],
      },
    };
  },
  components: {
    Chart,
  },
  computed: {
    ...mapState({
      city: (state) => state.city,
    }),
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
